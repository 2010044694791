import {
  CHECKBOX_CHANGE,
  HANDLE_FIELDS_VALIDITY,
  PROCESSING, SELECT_CHANGE,
  SET_USER_LOCALE,
  TEXT_CHANGE,
  TOGGLE_COLLAPSE,
  TOGGLE_MODAL
} from "../actions/actionTypes"
import {combineReducers} from "redux"


const initialState = {
  userLocale: null,
  isOpenModal: false,
  isShownCollapse: false,
  modalAction: null,
  isLoading: false,
}

const btuReducer = (state = initialState, action) => {

  switch (action.type) {

    case SET_USER_LOCALE:
      return {
        ...state,
        userLocale: action.payload.userLocale
      }

    case TOGGLE_MODAL:
      return {
        ...state,
        isOpenModal: !state.isOpenModal,
        modalAction: action.payload.modalAction
      }

    case TOGGLE_COLLAPSE:
      return {
        ...state,
        isShownCollapse: !state.isShownCollapse
      }

    case TEXT_CHANGE:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data
        }
      }

    case CHECKBOX_CHANGE:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data
        }
      }

    case SELECT_CHANGE:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data
        }
      }

    case HANDLE_FIELDS_VALIDITY:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data
        }
      }

    case PROCESSING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      }

    default:
      return state
  }
}


export const rootReducer = combineReducers({
  btu: btuReducer
})