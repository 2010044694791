import React, {Component} from 'react'
import {connect} from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css/animate.min.css'
import './assets/css/line-awesome.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './assets/css/style.css'

import BtuRouter from './config/BtuRouter'
import {setUserLocale} from "./actions/actionCreators"
import i18next from 'i18next'

class App extends Component {

  componentDidMount() {
    /*
    if (process.env.REACT_APP_ENV === 'PROD' && window.location.protocol === "http:") {
      window.location.protocol = "https://"
    }
    */

	  // Detect user locale and save it to the store
    this.props.dispatch(setUserLocale(i18next.language))
  }

  render() {
    return (
      <BtuRouter useSuspense={false}/>
    )
  }
}

App = connect(null, null)(App)
export default App
