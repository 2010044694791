import {
  PROCESSING,
  SET_USER_LOCALE,
  TEXT_CHANGE,
  TOGGLE_COLLAPSE,
  HANDLE_FIELDS_VALIDITY,
} from './actionTypes'

// Set the user locale
export function setUserLocale(locale) {
  return {
    type: SET_USER_LOCALE,
    payload: {
      userLocale: locale
    }
  }
}

// Toggle collapse Show/Hide actions
export function toggleCollapse() {
  return {
    type: TOGGLE_COLLAPSE
  }
}

// Handle form text input change
export function textChange(formName, inputName, inputValue) {
  return {
    type: TEXT_CHANGE,
    payload: {
      formName: formName,
      data: {
        [inputName]: inputValue
      }
    }
  }
}

// Handle loading indicator when process is triggered
export function loading(bool) {
  return {
    type: PROCESSING,
    payload: {
      isLoading: bool
    }
  }
}

// Handle form fields validity
export function handleValidity(formName, bool) {
  return {
    type: HANDLE_FIELDS_VALIDITY,
    payload: {
      formName: formName,
      data: {
        isValidFields: bool
      }
    }
  }
}
