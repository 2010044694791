import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col, Button} from "reactstrap"

import './banner.css'

class Banner extends React.Component {
  render() {
    const {
      id,
      text,
      button,
      buttonDisabled,
      onClick,
    } = this.props

    return (
      <section
        id={id}
      >
        <Container className="banner-container">
          <Row>
            <Col lg={{size: 12}} className="">
              <div className="d-flex align-items-center justify-content-center">
                <div className={'p-0'}>
                  <p>{text}</p>
                  <Button
                    className={'btn btn-primary d-inline-flex align-items-center justify-content-between'}
                    onClick={onClick}
                    disabled={buttonDisabled}
                  >
                    {button}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

Banner.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
}

Banner.defaultProps = {
  id: null,
  text: null,
  button: null,
  onClick: () => null,
  buttonDisabled: false,
}

export default Banner