import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from "reactstrap"

import constants from '../../config/constants'

import logo from '../../assets/icons/logo.png'
import marker from '../../assets/icons/marker.png'
import email from '../../assets/icons/email.png'

class Footer extends React.Component {
  render() {
    const {
      t
    } = this.props

    const images = require.context('../../assets/social', true)

    return (
      <footer>
        <Container>
          <Row>
            <Col lg={4} className={'mb-5 mb-lg-0'}>
              <h4 className={'f-title d-inline-flex align-items-center'}>
                <img src={logo} alt={t('global.brand')} className={'img-fluid mr-3'}/>
                <span className="uppercase">{t('global.brand')}</span>
              </h4>
              <p className={'f-about'}>
                {t('footer.description.about')}
              </p>

              <ul className="social-links">
                {constants.social.map(network => (
                  <li key={network.name}>
                    <a href={network.url} target={'_blank'} rel="noopener noreferrer">
                      <img src={images('./' + network.file)} alt={network.name} />
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col lg={4} className={'mb-5 mb-lg-0'}>
              <h4 className={'f-title'}>
                {t('footer.about.title')}
              </h4>
              <ul className="list-links">
                <li>
                  <a href={constants.url.btuProtocolAbout} target="_blank" rel="noopener noreferrer">
                    {t('footer.about.who')}
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={4} className={'mb-5 mb-lg-0'}>
              <h4 className={'f-title'}>
                {t('footer.contact.title')}
              </h4>
                <ul className={'list-contact'}>
                  <li className={'d-inline-flex w-100 mb-3 align-items-start'}>
                    <img src={marker} alt={t('footer.images.address')} />
                    <span className={'ml-3'}>
                      {t('footer.contact.address')}
                      <span className={'d-block mt-2'}>
                        {t('footer.contact.rcs')}
                      </span>
                    </span>
                  </li>

                  <li className={'d-inline-flex w-100 align-items-center'}>
                    <img src={email} alt={t('footer.images.email')} />
                    <span className={'ml-3'}>
                      <a href={'mailto:' + constants.url.mail} target="_blank" rel="noopener noreferrer">
                        {constants.url.mail}
                      </a>
                    </span>
                  </li>
                </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

Footer.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

Footer.defaultProps = { }

export default Footer