import React from 'react'
import PropTypes from 'prop-types'

import './form.css'

class TextInput extends React.Component {
  state = {
    value: '',
    error: true,
  }

  onChange = (value) => {
    const {
      onChange,
      fieldName,
    } = this.props

    const error = (value === null || value === '')

    this.setState({
      value,
      error,
    })

    onChange(
      fieldName,
      {
        value,
        error,
      },
    )
  }

  render() {
    const {
      label,
      placeholder,
      fieldName,
      displayErrors,
      errorMessage,
      emptyMessage,
    } = this.props

    const {
      value,
      error,
    } = this.state

    return (
      <div className="form_section">
        <label htmlFor={'form_' + fieldName}>
          {label}
        </label>
        <input
          type="text"
          className={displayErrors && error ? 'error' : ''}
          name={'form_' + fieldName}
          id={'form_' + fieldName}
          placeholder={placeholder}
          value={value}
          onChange={e => this.onChange(e.target.value)}
        />
        { displayErrors && error
          && (
            <div className="error_text">
              { value === '' ? emptyMessage : errorMessage}
            </div>
          )
        }
      </div>
    )
  }
}

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string,
  displayError: PropTypes.bool,
  onChange: PropTypes.func,
}

TextInput.defaultProps = {
  label: null,
  placeholder: null,
  fieldName: null,
  displayError: false,
  onChange: () => null,
}

export default TextInput