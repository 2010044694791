import React from 'react'
import {connect} from "react-redux"

import $ from 'jquery'
import WOW from 'wow.js'
import {withTranslation} from "react-i18next"

import Header from '../components/header/header'
import Intro from '../components/intro/intro'
import Steps from '../components/steps/steps'
import Section from '../components/section/section'
import Form from './form'
import Banner from '../components/banner/banner'
import Footer from '../components/footer/footer'

import introImage from '../assets/img/photo header.png'
import stepImage1 from '../assets/img/etape1.png'
import stepImage2 from '../assets/img/etape2.png'
import stepImage3 from '../assets/img/etape3.png'
import coinhouseSectionImage from '../assets/img/photo coinhouse.png'
import coinhouseLogoImage from '../assets/img/coinhouse.png'
import visionSectionImage from '../assets/img/photo vision.png'
import hababouImage from '../assets/img/herve hababou.png'
import chriquiImage from '../assets/img/vidal chriqui.png'
import constants from '../config/constants'

class Home extends React.Component {
  componentDidMount() {
    // WOW animations initialization
    new WOW().init()

    // Handle back to top button click
    $('.btn-scroll-top').on('click', function () {
      $('body,html').animate({scrollTop: 0}, 800)
    })

    if(window.innerWidth < 1192) {
      // Mobile Screen
      $('nav').removeClass('bg-white')
      $('nav').fadeIn().addClass('bg-light')
    }

    // Scroll header and toggle fixed position
    $(window).scroll(function () {
      let scrolledHeight = $(this).scrollTop()

      // Display back to top button
      if (scrolledHeight >= 100) {
          $('.btn-scroll-top').fadeIn(900).removeClass('d-none')
      } else if (!$('.btn-scroll-top').hasClass('d-none')) {
          $('.btn-scroll-top').fadeOut(900).addClass('d-none')
      }

      // Toggle fixed position header section
      if(window.innerWidth >= 1192) {
          // Desktop Screen
          if (scrolledHeight >= 50) {
              $('nav').removeClass('bg-white')
              $('nav').fadeIn().addClass('bg-light')
          } else {
              $('nav').removeClass('bg-light')
              $('nav').fadeIn().addClass('bg-white')
          }
      }
      else
      {
          $('nav').removeClass('bg-white')
          $('nav').fadeIn().addClass('bg-light')
      }
    })

    /*
    document.addEventListener('mousewheel', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('touchstart', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('touchmove', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('wheel', (event) => { event.preventDefault() }, { passive: true })
    */
  }

  /**
   * Fonction appelée au clic sur le bouton de contact
   */
  onClickContact = () => {
    window.open('mailto:' + constants.url.mail,'_blank');
  }

  /**
   * Scrolle vers le formulaire
   */
  scrollToForm = () => {
    let target = $('#form')
    $('html, body').stop().animate({ scrollTop: $(target).offset().top - 50 }, 600)
  }

  render() {
    const {
      t,
    } = this.props

    return (
      <React.Fragment>
        <Header useSuspense={false} />

        <Intro
          id='home'
          title={t('intro.title')}
          button={t('intro.button')}
          image={introImage}
          onClick={this.scrollToForm}
        />

        <Steps
          title={t('commitments.title')}
          steps={[
            {
              image: stepImage1,
              title: t('commitments.content.0.title'),
              text: t('commitments.content.0.text'),
            },
            {
              image: stepImage2,
              title: t('commitments.content.1.title'),
              text: t('commitments.content.1.text'),
            },
            {
              image: stepImage3,
              title: t('commitments.content.2.title'),
              text: t('commitments.content.2.text'),
            },
          ]}
        />

        <Section
          image={visionSectionImage}
          title={t('vision.title')}
          content={(
            <React.Fragment>
              { t('vision.content', { returnObjects: true }).map(text => (
                <p
                  key={text}
                  dangerouslySetInnerHTML={{__html: text}}
                />
              ))}
            </React.Fragment>
          )}
        />

        <Steps
          title={t('founders.title')}
          steps={[
            {
              image: hababouImage,
              title: t('founders.content.0.title'),
              text: t('founders.content.0.text'),
            },
            {
              image: chriquiImage,
              title: t('founders.content.1.title'),
              text: t('founders.content.1.text'),
            },
          ]}
          noLines
        />

        <Section
          image={coinhouseSectionImage}
          titleHTML={(<span>{t('coinhouse.title')} <img src={coinhouseLogoImage} alt="" /></span>)}
          content={(
            <React.Fragment>
              { t('coinhouse.content', { returnObjects: true }).map((text, index) => (
                <p dangerouslySetInnerHTML={{__html: text}} key={index} />
              ))}
            </React.Fragment>
          )}
          imageRight
        />

        <Form
          t={t}
          useSuspense={false}
        />

        <Banner
          text={t('contact.text')}
          button={t('contact.button')}
          onClick={this.onClickContact}
        />

        <Footer
          t={t}
          useSuspense={false}
        />
        <div className="btn-scroll-top d-none text-decoration-none">
          <i className="la la-long-arrow-up la-2x"></i>
        </div>
      </React.Fragment>
    )
  }
}

Home.propTypes = {}

Home.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
  }
}

Home = connect(mapStateToProps, null)(Home)
export default withTranslation()(Home)