import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import DE_RESOURCES from './translations/de.json'
import EN_RESOURCES from './translations/en.json'
import ES_RESOURCES from './translations/es.json'
import FR_RESOURCES from './translations/fr.json'
import ID_RESOURCES from './translations/id.json'
import IT_RESOURCES from './translations/it.json'
import JA_RESOURCES from './translations/ja.json'
import KR_RESOURCES from './translations/kr.json'
import PT_RESOURCES from './translations/pt.json'
import RU_RESOURCES from './translations/ru.json'
import TR_RESOURCES from './translations/tr.json'
import ZH_RESOURCES from './translations/zh.json'


// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({

    detection: {
      order: ['navigator'],
    },

    resources: {
      de: { translation: DE_RESOURCES },
      en: { translation: EN_RESOURCES },
      es: { translation: ES_RESOURCES },
      fr: { translation: FR_RESOURCES },
      id: { translation: ID_RESOURCES },
      it: { translation: IT_RESOURCES },
      ja: { translation: JA_RESOURCES },
      kr: { translation: KR_RESOURCES },
      pt: { translation: PT_RESOURCES },
      ru: { translation: RU_RESOURCES },
      tr: { translation: TR_RESOURCES },
      zh: { translation: ZH_RESOURCES },
    },

    fallbackLng: 'en',

    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }

  })


export default i18n