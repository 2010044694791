import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from "reactstrap"

import './steps.css'

class Steps extends React.Component {
  render() {
    const {
      id,
      title,
      steps,
      noLines,
    } = this.props

    let size = 12
    if (steps) {
      switch (steps.length) {
        case 2:
          size = 6
          break
        case 3:
          size = 4
          break
        case 4:
          size = 3
          break
        case 5:
        case 6:
          size = 2
          break
        default:
          size = 12
      }
    }

    return (
      <section
        id={id}
      >
        <Container className="steps-container">
          <Row>
            <Col className="steps-title">
              <h1
                className="section-title text-center"
                dangerouslySetInnerHTML={{__html: title}}
              />
            </Col>
          </Row>
          <Row className="steps-cells-container">
            {steps.map(step => (
              <Col lg={{size: size}} key={step.title} className={'steps-cell' + (noLines ? ' steps-cell-no-line': '')}>
                <div className="steps-cell-image-wrapper">
                  <img src={step.image} alt="" />
                </div>
                <h2>{step.title}</h2>
                <p>{step.text}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    )
  }
}

Steps.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  steps: PropTypes.instanceOf(Array),
  noLines: PropTypes.bool,
}

Steps.defaultProps = {
  id: null,
  title: null,
  steps: [],
  noLines: false,
}

export default Steps