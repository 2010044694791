import React from 'react'
import {connect} from "react-redux"
import {setUserLocale, toggleCollapse} from "../../actions/actionCreators"
import {withTranslation} from 'react-i18next'

import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
} from 'reactstrap'

import $ from 'jquery'

import logo from '../../assets/img/logo.png'

import constants from '../../config/constants'

import './header.css'

/**
 * 
 */
class Header extends React.Component {
  state = {
    dropdownOpen: false
  }

  componentDidMount() {
    this.bindMenuItem()
  }

  toggleLanguageMenu = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  /**
   * Relie les liens internes du header (du genre href="#xxx") à un élément de la page
   */
  bindMenuItem = () => {
    $("a[href*='#'], button[data-target*='#']").bind('click', function(e) {
      if ($(this).attr("href") !== '#') {
        e.preventDefault()
        console.log($(this).attr("href"))
        // Récupère l'id vers lequel on doit pointer
        let target = $(this).attr("href") ? $(this).attr("href") : $(this).attr("data-target")
        // Vérifie que l'id existe réellement
        if(target !== '#' && $(target).length > 0) {
          // L'id existe : on scrolle vers lui
          $('html, body').stop().animate({ scrollTop: $(target).offset().top - 50 }, 600)
        }
        return false
      }

      return true
    })
  }

  /**
   * Change la langue
   *
   * @param {string} locale - Langue
   */
  setLocale = (locale) => {
    let {i18n, dispatch} = this.props
    // Set user locale
    i18n.changeLanguage(locale)
    // Dispatch the update action
    dispatch(setUserLocale(locale))
  }

  onChangeLanguage = (language) => {
    this.setLocale(language)
  }

  render() {
    let {
      t,
      userLocale,
      isShownCollapse,
      dispatch
    } = this.props

    let currentLanguage = 'en'
    if (userLocale) {
      currentLanguage = userLocale.substring(0, 2)
    }

    const images = require.context('../../assets/languages', true)

    return (
      <header>
        <Navbar color="light" expand="xl" className={'bg-white fixed-top'}>
          <Container>
            <NavbarBrand href="/" className={'app-logo d-inline-flex align-items-center'}>
              <img src={logo} alt={t('global.brand')} />
              <span className="uppercase">{t('global.brand')}</span>
            </NavbarBrand>
            <NavbarToggler
              onClick={() => dispatch(toggleCollapse())}
              children={<i className={'la la-bars'}></i>}
              name="navbar-toggler"
              aria-label="Navbar Toggler"
            />
            <Collapse isOpen={isShownCollapse} navbar>
              <Nav className="ml-auto d-flex align-items-center" navbar>
                <NavItem>
                  <NavLink href={constants.url.btuProtocolAbout} target="_blank" rel="noopener noreferrer">{t('header.who')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#form" className={'btn btn-outline-started d-inline-flex align-items-center uppercase'}>
                    {t('header.button')}
                  </NavLink>
                </NavItem>

                <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleLanguageMenu}>
                  <DropdownToggle nav caret>
                    <img src={images('./flag_' + currentLanguage + '.png')} alt="" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {constants.languages.map((language, index) => (
                      <DropdownItem onClick={event => this.onChangeLanguage(language)} key={index}>
                        <img src={images('./flag_' + language + '.png')} alt="" />
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  }
}

Header.propTypes = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
    isShownCollapse: state.btu.isShownCollapse
  }
}

Header = connect(mapStateToProps,null)(Header)
export default withTranslation()(Header)